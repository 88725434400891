import React from 'react';
import {Helmet} from "react-helmet";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/Home';
import Card from '@material-ui/core/Card';
/*import CardActions from '@material-ui/core/CardActions';*/
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ContactUs from './ContactUs';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';

import ReactGA from 'react-ga';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://3dsn.com/">
        3DSN.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor:'pointer',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  logoBox: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  fab: {
    margin: theme.spacing(1),
    align:"right",
  },
  dialogToolbarLeft: {
    minWidth: 30,
    textAlign: 'left',
  },
  dialogToolbarRight: {
    minWidth: 30,
    textAlign: 'right',
  },
  donors: {
    cursor:'pointer',
    color:'#3f51b5',
  },
  donateLink:{
    textAlign:'center',
    marginTop: theme.spacing(1),
  },
}));



export default function Album() {
  const classes = useStyles();
  /*const preventDefault = (event) => event.preventDefault();*/

  const [open, setOpen] = React.useState(false);

  const [imageLink, setImageLink] = React.useState("");
  const [vsHeader, setVsHeader] = React.useState("");

  const getHelmet = () => {
      return (
        <Helmet>
        <meta charSet="utf-8" />
          <title>{`3DSN.com: 3D Support Network`}</title>
          <link rel="canonical" href="https://3dsn.com" />
          <meta name="keywords" content="ppe,covid,covid-19,covid19,wuhan,china,virus,wuhan virus,chinese virus,ccp virus,pandemic,epidemic,disease,vaccine,face shield,face mask,face protection,doctors,nurses,medical personnel,hospital,medical office,dental,surgery,icu,paramedic,emergency,urgent care,donation,volunteer,3d,3d printing,3d modeling,3d design" />
          <meta name="description" content="3DSN: Making PPE for medical personnel" />
        </Helmet>
      );
  }

  const handleClickOpen = (imageLink, header) => {
    setImageLink(imageLink);
    setVsHeader(header);
    setOpen(true);
    ReactGA.event({
      category: 'Image Loaded',
      action: `URL: ${imageLink}`
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openContact, setOpenContact] = React.useState(false);
  const handleClickOpenContact = () => {
    setOpenContact(true);
    ReactGA.event({
      category: 'Contact Form',
      action: `Open`
    });
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };


  return (
    <div>
      {getHelmet()}
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
            3DSN.com
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              3D Support Network
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Making PPE for medical personnel and small businesses.<br />This is our contribution to fight COVID-19.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleClickOpenContact}>
                    Order / Contact us
                  </Button>

                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>

              <Grid item key='card-01' xs={12} sm={6} md={4}>
                <Card className={classes.card}
                  onClick={()=>handleClickOpen("./images/3ds-fs-sweeden-full.jpg", "Model 1")}
                >
                  <CardMedia
                    className={classes.cardMedia}
                    image="./images/3ds-fs-sweeden-thumb.jpg"
                    title="Model 1"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Model 1
                    </Typography>
                    <Typography>
                      Simple design, one-piece frame, no additional head band needed. Comes with chin piece. Based on 3dverkstan original design.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key='card-02' xs={12} sm={6} md={4}>
                <Card className={classes.card}
                  onClick={()=>handleClickOpen("./images/3ds-fs-prusa-full.jpg", "Model 2")}
                >
                  <CardMedia
                    className={classes.cardMedia}
                    image="./images/3ds-fs-prusa-thumb.jpg"
                    title="Model 2"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Model 2
                    </Typography>
                    <Typography>
                      Remix of popular design (Prusa) with extra-wide chin piece, removable visor clips, removable elastic head band.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key='card-03' xs={12} sm={6} md={4}>
                <Card className={classes.card}
                  onClick={()=>handleClickOpen("./images/3ds-hb-full.jpg", "Ear saver")}
                >
                  <CardMedia
                    className={classes.cardMedia}
                    image="./images/3ds-hb-thumb.jpg"
                    title="Ear saver"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Ears saver
                    </Typography>
                    <Typography>
                      Surgical mask ears saver. Multiple designs. Made with flexible material TPU (thicker) and semi-flexible PLA (thinner).
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            
          </Grid>
        </Container>
        <br />

        <br />
        <Divider />
        <br />
        <br />
        <Typography component="h5" variant="h5" align="center" className={classes.donors} gutterBottom
        onClick={()=>handleClickOpen("./images/donors.jpg", "Big Thanks to our donors")}
        >
          Big Thanks to our donors!
        </Typography>
        <br />
        <br />
        <Divider />
        <br />
        <br />
        <Typography component="h5" variant="h5" align="center" color="textPrimary" gutterBottom>
          Some of our recipients:
        </Typography>
        <Box className={classes.logoBox} >
          <img src="/images/kaiser.png" />
          <img src="/images/valley-med.png" />
        </Box>

      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>

    <React.Fragment>
    <Dialog onClose={handleClose} aria-labelledby="dialog-model-1-preview" open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="simple-dialog-title">
              <Grid container spacing={2}>
                <Grid item xs className={classes.dialogToolbarLeft}>
                  {vsHeader}
                </Grid>
                <Grid item xs className={classes.dialogToolbarRight}>
                    <Fab 
                    color="primary" 
                    aria-label="Close" 
                    size="small" 
                    className={classes.fab} 
                    onClick={handleClose}
                    >
                    <CloseIcon />
                    </Fab>
                </Grid>
              </Grid>
      </DialogTitle>
        <DialogContent>
        <Box className={classes.logoBox}>
          <img alt="3DSN design" src={imageLink} />
        </Box>
        </DialogContent>
    </Dialog>
    </React.Fragment>

    <React.Fragment>
    <Dialog onClose={handleCloseContact} aria-labelledby="simple-dialog-title" open={openContact} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="simple-dialog-title-contact">
            <Grid container spacing={2}>
                <Grid item xs className={classes.dialogToolbarRight}>
                    <Fab 
                    color="primary" 
                    aria-label="Close" 
                    size="small" 
                    className={classes.fab} 
                    onClick={handleCloseContact}
                    >
                    <CloseIcon />
                    </Fab>
                </Grid>
            </Grid>
      </DialogTitle>
        <DialogContent>
          <ContactUs />
        </DialogContent>
    </Dialog>
    </React.Fragment>
    </div>
  );
}


